import { Grid, Typography } from "@mui/material"
import CabeceraApp from "../CabeceraApp"

const PageNormas = ({apartamento}) => {
    return (
      <>
      
      <CabeceraApp apartamentoNombre={apartamento.nombre} />
  
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        
      >
          
          <Grid item xs={12} sx={{display: 'flex', justifyContent:'center', marginBottom: 5}}>
            <img src={'../assets/img/normas.png'} alt="Logo" />
          </Grid>  
          
          <Grid item xs={12} sx={{display: 'flex', justifyContent:'center', paddingX: 3, marginBottom: 3}}>
            <Typography>
              Deseamos que disfrutes de la estancia, por lo que creemos que es asunto de todos el conocer y cumplir unas normas básicas de convivencia. Puedes leer estas normas a continuación:
            </Typography>
          </Grid>  
      </Grid>

      <Grid
        container
        spacing={0}
        direction="column"
        sx={{paddingLeft: 3, paddingRight: 3}}
      >
      <Typography variant='h5'>NORMAS DEL ESTABLECIMIENTO</Typography>
  
      <Typography variant='body1' sx={{paddingTop: 2}}>1. Se establece una fianza de 150,00€ a cuenta de posibles daños materiales a los bienes muebles del apartamento o deterioro en cualquier parte del establecimiento. Será motivo de no devolución de la misma en caso de incumplimiento de las normas de este reglamento.</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>2. La reserva del alojamiento comienza a las 16.00 horas del primer día del periodo contratado, y finaliza a las 12.00 horas del día señalado como fecha de salida. La prolongación del horario señalado, sin previo acuerdo, dará derecho al establecimiento a cargar el equivalente a un día adicional.</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>3. No se permitirá la estancia en la unidad de alojamiento a más personas de las contratadas en el mismo, ni podrán registrarse en número superior a la capacidad máxima de cada apartamento. Dado el primer caso, se abonará la tarifa fijada por ocupante extra, y en el segundo ejerceremos el derecho de admisión desalojando a todos los ocupantes del apartamento.</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>4. Está prohibido fumar en todo el establecimiento, a excepción de los espacios al aire libre.</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>5. No están permitidas las mascotas en el establecimiento</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>6. La circulación y estancia dentro del establecimiento, será solo en los lugares reservados para los clientes, sin que éstos puedan acceder en ningún caso, a las estancias o espacios reservados o privados.</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>7. No está permitido utilizar las toallas y otras prendas del apartamento para su uso exterior. No está permitido colgar ninguna prenda en las fachadas.</Typography>
      <Typography variant='body1' sx={{paddingTop: 2}}>8. La Dirección del establecimiento ruega que</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Asegúrese de que la puerta y ventanas quedan bien cerradas al salir del apartamento, aun cuando sea por un corto especio de tiempo.</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Mantenga la puerta cerrada cuando esté en el apartamento</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• No permita al personal de mantenimiento entrar en su apartamento si no está identificado ni entregas que no han sido solicitadas.</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• No cuelgue ropa sobre la barandilla de la terraza hacia el exterior de la fachada.</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Si descubre algún tipo de deterioro o anomalía, comuníquelo lo antes posible y a ser posible a su llegada para evitar confusiones.</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Respete las horas de descanso, especialmente durante la noche y la siesta. Evite hacer ruido innecesario.</Typography>
      <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Utilice las instalaciones adecuadamente, respetando el mobiliario.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>9. Está expresamente prohibido, a cualquier hora del día y podrá ser motivo de ejecución del derecho de admisión en el establecimiento:</Typography>
    <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Hacer uso de equipos de música cuyo volumen pueda molestar al resto de clientes, así como el uso de bocinas, megáfonos o similares.</Typography>
    <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Hacer fiestas</Typography>
    <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Reunirse en las zonas comunes para el consumo de bebidas alcohólicas.</Typography>
    <Typography variant='body1' sx={{paddingTop: 1, paddingLeft: 3}}>• Alojar en el apartamento a clientes no registrados a la entrada o a más personas de la cantidad máxima estipulada en cada unidad de alojamiento.</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>10. Cualquier desperfecto en el mobiliario, utensilios, etc., que la Dirección considere que ha sido intencionado o fruto del mal uso, será cargado al cliente y a su vez se reserva el derecho de pedir al cliente que abandone el establecimiento inmediatamente.</Typography>
  
    <Typography variant='h5' sx={{paddingTop: 3, paddingBottom: 3, fontWeight: 'bold'}}>REGLAMENTO DE RÉGIMEN INTERIOR DEL ESTABLECIMIENTO DENOMINADO “APARTAMENTOS BRISAS DEL PUERTO”</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>Conforme al Art. 31 del Decreto 194/2010, de 20 de abril, de establecimientos de apartamentos turísticos, el establecimiento puede disponer de un reglamento de régimen interior, que será de obligado cumplimiento para las personas usuarias durante su estancia:</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>Artículo 31. Reglamento de régimen interior.</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>1. Los establecimientos de apartamentos turísticos podrán disponer de un reglamento de régimen interior estableciendo normas de obligado cumplimiento para las personas usuarias durante su estancia.</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>2. El reglamento de régimen interior podrá determinar las condiciones de admisión, las normas de convivencia y funcionamiento, así como todo aquello que permita y favorezca el disfrute normal de las instalaciones, equipamientos y servicios, sin que pueda contravenir lo dispuesto en la Ley 12/1999, de 15 de diciembre, ni en el presente Decreto.</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>3. El reglamento de régimen interior estará siempre a disposición de las personas usuarias y será expuesto, al menos, en castellano e inglés, en lugar visible de la conserjería-recepción del establecimiento. En caso de que no exista esta, se dispondrá un ejemplar impreso en cada unidad de alojamiento.</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>Ley 13/2011, de 23 de diciembre, del Turismo de Andalucía. Comunidad Autónoma de Andalucía. «BOJA» núm. 255, de 31 de diciembre de 2011 «BOE» núm. 17, de 20 de enero de 2012 Referencia: BOE-A-2012-876</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>Artículo 36. Acceso y permanencia en los establecimientos de alojamiento turísticos.</Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>2. El acceso y la permanencia en los establecimientos turísticos podrán condicionarse al cumplimiento de sus normas de régimen interior, que no podrán contravenir lo dispuesto en la presente Ley o su normativa de desarrollo. La existencia de dichas normas deberá anunciarse de forma visible en los lugares de acceso al establecimiento y darse a conocer a las personas usuarias de servicios turísticos.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>3. Las personas titulares de los establecimientos podrán impedir la permanencia en los mismos de las personas usuarias que incumplan alguno de los deberes que establece el artículo 22 de esta Ley.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>4. Las personas titulares de los establecimientos turísticos podrán recabar el auxilio de las Fuerzas y Cuerpos de Seguridad para desalojar de los mismos a las personas que incumplan las reglas usuales de convivencia social, sus normas de régimen interior, o que pretendan acceder o permanecer en los mismos con una finalidad diferente al normal uso del servicio Artículo </Typography>
  
    <Typography variant='body1' sx={{paddingTop: 2}}>22. Obligaciones.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>A los efectos de esta Ley, y sin perjuicio de lo establecido en otra legislación aplicable, las personas usuarias de servicios turísticos tienen la obligación de:</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>a) Observar las reglas de convivencia e higiene dictadas para la adecuada utilización de los establecimientos turísticos.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>b) Respetar las normas de régimen interior de los establecimientos turísticos, siempre que no sean contrarias a la ley.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>c) En el caso del servicio turístico de alojamiento, respetar la fecha pactada de salida del establecimiento dejando libre la unidad ocupada.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>d) Respetar los establecimientos, instalaciones y equipamientos de las empresas turísticas.</Typography>
    <Typography variant='body1' sx={{paddingTop: 2}}>e) Respetar el entorno medioambiental, el patrimonio histórico y cultural y los recursos turísticos de Andalucía.</Typography>
  
  </Grid>
      </>
    )
  }
  
  export default PageNormas