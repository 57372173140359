import { Grid, Paper, Typography } from "@mui/material"
import { TELEFONO_CONTACTO } from "../../constants/general"
import CabeceraApp from "../CabeceraApp"
import contactos from "../../data/contactos.json";

const PageContacto = ({apartamento}) => {

    return (
      <>
      
      <CabeceraApp apartamentoNombre={apartamento.nombre} />
  
      {
        contactos.map((item) => {
          return (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"        
                paddingX={5}
                marginY={2}
              >
                <Grid item xs={12} sx={{backgroundColor: 'white', borderRadius: 4}}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"        
                    paddingX={1}
                    marginY={2}
                  >
                  
                    <Grid item xs={2} sx={{display: 'flex', justifyContent:'center'}}>
                      <img src={item.logo} alt="Logo" style={{borderRadius: 50}} />
                    </Grid> 
                    <Grid item xs={8} sx={{paddingLeft: 1}}>
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"        
                      >
                        <Grid item xs={12} sx={{}}>
                          <Typography sx={{fontWeight: 'bold'}}>{item.nombre}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{}}>
                          <a href={`tel:${item.telefono}`}>{item.telefono_str}</a>
                        </Grid>
                      </Grid> 
                    </Grid> 
                    <Grid item xs={2} sx={{display: 'flex', justifyContent:'center'}} >
                      <a href={`tel:${item.telefono}`}><img src={'../assets/img/iconos/IconTelefono.png'} alt="Logo" /></a>
                    </Grid> 
        
                  </Grid> 
                </Grid> 
              </Grid> 
          )
        })
      }

        {/* <Paper elevation={0} sx={{ width: '100%', paddingLeft: 3, paddingRight: 3}}>
          <Paper elevation={4} sx={{width: '100%',padding: 3, borderRadius: 5}}>
            <Grid item xs={12} sx={{display: 'flex', justifyContent:'center'}}>
              <img src={'../assets/img/telefono.png'} alt="Logo" width={130} />
            </Grid> 
  
            <Grid item xs={12} sx={{paddingTop: 5}}>
              <Grid item xs={12}>
                <Typography sx={{fontSize: 20, fontWeight: 'bold', textAlign: 'center'}}>Telefono de contacto</Typography>
              </Grid>  
              <Grid item xs={12}>
                <Typography sx={{fontSize: 20}} align='center'><a href={`tel:${TELEFONO_CONTACTO}`} style={{textDecoration: 'none'}}>{TELEFONO_CONTACTO}</a></Typography>
              </Grid>  
            </Grid>  
          </Paper>
        </Paper> */}
      </>
    )
  }

  export default PageContacto