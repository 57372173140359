import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import CabeceraApp from "../CabeceraApp"

import entorno from '../../data/entorno.json'
import { CARD_BACKGROUNDCOLOR, CARD_COLOR, CARD_COLOR_SECUNDARY, MAIN_BACKGROUNDCOLOR } from "../../constants/theme"

const PageCerca = ({apartamento}) => {
    return (
      <>
      
      <CabeceraApp apartamentoNombre={apartamento.nombre} />
   
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        marginBottom={5}
      >
  
        { 
          entorno.map( (item) => {
            return (

              ( item.es_bicheando === 'true' ?

              <a href={item.url} style={{textDecoration: 'none'}}>
                <Card sx={{ width: 345, marginBottom: 2 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={item.img}
                      alt={item.titulo}
                    />
                  </CardActionArea>
                </Card>
              </a>
              
              :
              <a href={item.url} style={{textDecoration: 'none'}}>
                <Card sx={{ width: 345, marginBottom: 2 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={item.img}
                      alt={item.titulo}
                    />
                    <CardContent sx={{backgroundColor: CARD_BACKGROUNDCOLOR}}>
                      <Typography gutterBottom variant="h5" component="div" color={CARD_COLOR}>
                        {item.titulo}
                      </Typography>
                      <Typography variant="body2" color={CARD_COLOR_SECUNDARY}>
                        {item.descripcion}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </a>

              )
            )
          })
        }
        

      {/* <a href="https://bicheandoalmeria.com/descarga" target="_blank">
        <img src={'../assets/img/bicheando.png'} alt="Logo" />
      </a> */}
      </Grid>
      </>
    )
  }
  
  export default PageCerca