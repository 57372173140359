import { Divider, Grid, Typography } from "@mui/material"
import { MAIN_COLOR } from "../constants/theme"

const CabeceraApp = ({apartamentoNombre}) => {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{paddingTop: 20}}
        >

          <Grid item xs={3}>
            <img src={'../assets/img/logo_horizontal.png'} alt="Logo" />
          </Grid>  
          <Grid item xs={3} sx={{paddingY:2}}>
            <Typography variant="h7" color={MAIN_COLOR}>{apartamentoNombre}</Typography>
          </Grid>

        </Grid>
        <Divider light sx={{marginBottom: 4, backgroundColor: '#ffffff', height: 2, borderColor: '#ffffff'}}/>
      </>
    )
  }

  export default CabeceraApp