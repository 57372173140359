export const MAIN_BACKGROUNDCOLOR = "#FFE6D6";
export const MAIN_COLOR = "#081F33";

export const CARD_BACKGROUNDCOLOR = "#081F33";
export const CARD_COLOR = "#FFFFFF";
export const CARD_COLOR_SECUNDARY = "#D0D0D0";

export const NAVBAR_HEIGHT = 80;
export const NAVBAR_BACKGROUNDCOLOR = "#FFFFFF";
export const NAVBAR_COLOR = "#081F33";
