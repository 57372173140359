import { Grid, Paper, Typography } from "@mui/material"
import { MAIN_BACKGROUNDCOLOR } from "../../constants/theme"
import CabeceraApp from "../CabeceraApp"

const PageWifi = ({apartamento}) => {  
    return (
      <>
      
      <CabeceraApp apartamentoNombre={apartamento.nombre} />
  
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        marginBottom={10}
      >
          
          <Grid item xs={12} sx={{display: 'flex', justifyContent:'center', marginBottom: 5}}>
            <img src={'../assets/img/wifi.png'} alt="Logo" width={120} />
          </Grid>  
          
          <Grid item xs={12} sx={{display: 'flex', justifyContent:'center', paddingX: 5}}>
            <Typography>
              Sabemos que te gusta navegar, para hacerlo con nuestra red de alta velocidad te lo ponemos fácil, conéctate a la red wifi e introduce la contraseña.
            </Typography>
          </Grid>  
  
          <Grid item xs></Grid>
          <Grid item xs={3} sx={{paddingTop: 3}}>
            <Grid container sx={{display: 'flex', justifyContent:'center'}}>
              <Grid item xs={12} sx={{marginBottom: 2}}>
                Red
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} sx={{paddingY: 2, paddingX: 2, borderRadius: 3}}>
                  {apartamento.wifi_ssid}
                </Paper>
              </Grid>

              <Grid item xs={12} sx={{marginBottom: 2, marginTop: 4}}>
                Contraseña
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} sx={{paddingY: 2, paddingX: 2, borderRadius: 3}}>
                  {apartamento.wifi_pass}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs></Grid>

      </Grid> 
      </>
    )
  }

  export default PageWifi