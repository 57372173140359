import { Avatar, Grid, Paper, Typography } from "@mui/material"
import { MAIN_BACKGROUNDCOLOR, MAIN_COLOR } from "../../constants/theme"
import CabeceraApp from "../CabeceraApp"

const PageManuales = ({apartamento}) => {
    return (
      <>
      
      <CabeceraApp apartamentoNombre={apartamento.nombre} />
  
      <Grid item xs={12} sx={{display: 'flex', justifyContent:'center', paddingX: 5, marginBottom: 5}}>
        <Typography>
        Es muy importante que sepas como funcionan todas las cosas. A continuación, instrucciones en vídeo, sencillas y rápidas.
        </Typography>
      </Grid>  

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        marginBottom={5}
      >
      
      
      { 
        apartamento.electrodomesticos.map( (item) => {
          return (
            <Paper key={item.nombre} elevation={0} sx={{ width: '100%', paddingLeft: 5, paddingRight: 5, backgroundColor: MAIN_BACKGROUNDCOLOR}}>
  
              <a href={item.url} target="_blank" rel='noreferrer' style={{textDecoration: 'none'}}>
                <Paper elevation={4} sx={{width: '100%',padding: 2, marginBottom: 2, borderRadius: 2}}>
  
                  <Grid container>
                    <Grid item xs={3}>
                      <Avatar src={item.avatar} variant="rounded" style={{marginLeft: 15}} />
                    </Grid>
                    <Grid item xs={9} alignSelf='center' sx={{}}>
                      <Typography color={MAIN_COLOR} sx={{fontWeight: 'bold', fontSize: 20}}>{item.nombre}</Typography>
                    </Grid>
                  </Grid>
  
                </Paper>
              </a>
            </Paper>
          ) 
        })
      }
    
      </Grid>
      </>
    )
  }
  
  export default PageManuales