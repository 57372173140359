import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import WifiIcon from '@mui/icons-material/Wifi';
import HomeIcon from '@mui/icons-material/Home';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GppGoodIcon from '@mui/icons-material/GppGood';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';

import PhoneIcon from '@mui/icons-material/Phone';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SailingIcon from '@mui/icons-material/Sailing';
import GavelIcon from '@mui/icons-material/Gavel';
import { useParams } from 'react-router-dom';

import apartamentos from './data/apartamentos.json'

import PageWifi from './components/tabs/PageWifi'
import PageContacto from './components/tabs/PageContacto'
import PageManuales from './components/tabs/PageManuales'
import PageCerca from './components/tabs/PageCerca'
import PageNormas from './components/tabs/PageNormas'

import { MAIN_BACKGROUNDCOLOR, NAVBAR_BACKGROUNDCOLOR, NAVBAR_COLOR, NAVBAR_HEIGHT } from './constants/theme';


/* -------------------------------------------------------------------------- */
/*                                  Contenido                                 */
/* -------------------------------------------------------------------------- */
const Contenido = ({value, apartamento}) => {
  return ( 
    <>
      {value === 0 && apartamento && <PageWifi apartamento={apartamento}/>}
      {value === 1 && <PageContacto apartamento={apartamento} />}
      {value === 2 && <PageManuales apartamento={apartamento}/>}
      {value === 3 && <PageCerca apartamento={apartamento}/>}
      {value === 4 && <PageNormas apartamento={apartamento}/>}
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*                                     App                                    */
/* -------------------------------------------------------------------------- */
export default function App() {
  const ref = React.useRef(null);
  
  let { apartamentID } = useParams();

  const [value, setValue] = React.useState(0);
  const [apartamento, setApartamento] = React.useState({});
  
  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);
  
  React.useEffect(() => {
    (async () => {
      try {
        const ap = apartamentos.filter(obj => {
          return obj.id === apartamentID
        })

        setApartamento(ap[0]);
      } catch {}
    })(apartamento)

  }, [apartamentID]);


  return (
    <Box sx={{ pb: 7, backgroundColor: MAIN_BACKGROUNDCOLOR }} ref={ref}>
      <CssBaseline />
      <Contenido value={value} apartamento={apartamento} />
      
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            height: NAVBAR_HEIGHT,
            // backgroundColor: '#081F33',
            backgroundColor: NAVBAR_BACKGROUNDCOLOR
          }}
        >
          <BottomNavigationAction sx={{color: NAVBAR_COLOR}} label="WIFI" icon={<WifiIcon />} />
          <BottomNavigationAction sx={{color: NAVBAR_COLOR}} label="Contactos" icon={<ContactPageIcon />} />
          <BottomNavigationAction sx={{color: NAVBAR_COLOR}} label="Casa" icon={<HomeIcon />} />
          <BottomNavigationAction sx={{color: NAVBAR_COLOR}} label="Entorno" icon={<LocalFloristIcon />} />
          <BottomNavigationAction sx={{color: NAVBAR_COLOR}} label="Normas" icon={<GppGoodIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
